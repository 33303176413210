import React, { useState } from 'react';

import {
    title as titleClass,
    formContainer,
    formGrid,
    successOutput,
} from './password-change.module.scss';
import { passwordChangeForm } from '../../formik/password-change.form';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import useTranslations from '../../hooks/use-translations';
import { useUser } from '../../hooks/use-user';

import FormGenerator from '../molecules/form-generator';
import UserLayout from '../../layouts/user-layout';

export interface IPasswordChangeSection extends ISection {
    items: {
        links: INavLink[];
    };
}

export interface IPasswordChangeProps {
    className?: string;
    section: IPasswordChangeSection;
}

const PasswordChange = ({ className = '', section }: IPasswordChangeProps) => {
    const t = useTranslations('PasswordChange');
    const { sectionId, css, style } = section;
    const [subtitle] = section.content.texts;
    const { links } = section.items;
    const user = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(user.changePassword.errors);
    const [visibleSuccessOutput, setVisibleSuccessOutput] = useState(false);

    const formSubmit = async (values: any, actions: any) => {
        try {
            await user.changePassword.fetch(values).unwrap();
            setVisibleSuccessOutput(true);
            actions.resetForm();

            setTimeout(() => {
                setVisibleSuccessOutput(false);
            }, 3000);
        } catch {}
    };

    return (
        <UserLayout
            sectionId={sectionId}
            className={className}
            links={links}
            css={css}
            style={style}
        >
            <p className={titleClass}>{subtitle}</p>
            <FormGenerator
                formRef={formikRef}
                name="PasswordChangeForm"
                className={formContainer}
                formClassName={formGrid}
                formikProps={{ onSubmit: formSubmit }}
                fields={passwordChangeForm}
                submitLoading={user.changePassword.isLoading}
                errorMessage={globalErrorMessage}
            >
                {visibleSuccessOutput && <output className={successOutput}>{t.success}</output>}
            </FormGenerator>
        </UserLayout>
    );
};

export default PasswordChange;
